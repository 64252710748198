
h1 {
  color: var(--Black);
  font-size: 36px;
  font-weight: 660;
  line-height: 120%;

  span {
    color: var(--Primary-Colour);
    font-weight: 700;
    font-size: inherit;
  }

  @media (min-width: 768px) {
  font-size: 58px;
    
  }
}


h2 {
  color: var(--Black, #2D2D2D);
  font-size: 28px;
  font-weight: 660;
  line-height: 130%; 

  span {
    color: var(--Primary-Colour, #4285F4);
    font-size: inherit;
  }

  @media (min-width: 768px) {
    font-size: 36px;
  }
}

h5 {
  color: #414141;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
}

p {
  color: #848484;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

a {
  text-decoration: none;
}