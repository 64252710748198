@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=M+PLUS+Rounded+1c:wght@400;700&family=Open+Sans:wght@300;400;600;700&display=swap');

@import './shared/utils/reset';
@import './shared/utils/typography';

:root {
  --Black: #2D2D2D;
  --Primary-Colour: #4285F4;
  --White: #fff;
}

*{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}



.container {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;

  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1200px) {
    padding: unset;
  }

}

.logo {
  color: #0075FF;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
}

.container-fluid {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 992px) {
    padding: unset;
  }
}

.btn {
  display: flex;
  width: 100%;
  padding: 19px 24px 19px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

  color: var(--White);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 130%; /* 22.1px */
  text-decoration: none;

  @media (min-width: 768px) {
    width: fit-content;
  }
}

.btn-isset {
  color: var(--Primary-Colour);
  padding: 11px 24px 11px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%; /* 20px */
  letter-spacing: -0.24px;
  border-radius: 80px;
  border: 1px solid #F0F0F0;
  background: #FBFCFF;
}

.btn-filled {
}

.btn-medium {
  min-width: 234px;
  min-height: 66px;
}

.btn-less {
  padding: 10px 24px;
  font-size: 16px;
}