.badge {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--White);
  color: #000;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}