.accordion {
  &__item {
    &.open {
      .accordion__header {
        border-radius: 16px 16px 0 0 ;
  
        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'%3E%3Cpath d='M3 12.3333L10 5.33325L17 12.3333' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  
          background-color: var(--Primary-Colour);
        }
      }
      .accordion__body {
        display: block;
      }
    }
  }
}