.accordion {
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  // .accordion__header
  &__header {
    border-radius: 16px;
    background: #F9F9F9;
    padding: 24px;
    padding-right: 74px;
    position: relative;
    cursor: pointer;


    &::after {
      content: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 8.66675L10 15.6667L3 8.66675' stroke='white' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-color: #A0C2F9;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media (min-width: 768px) {
      padding: 28px;
      padding-right: 78px;
    }
  }

  // .accordion__body

  &__body {
    display: none;
    border-radius: 0 0 16px 16px;
    background: #F9F9F9;
    padding: 0 28px 40px;
  }
}